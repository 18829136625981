





import { defineComponent } from 'vue';

import { relatedStoreMapper } from '@/modules/related/dist/store';

import RelatedLogo from './related-logo.vue';

export default defineComponent({
  name: 'RelatedHomeBookingLogo',
  components: {
    RelatedLogo,
  },
  computed: {
    ...relatedStoreMapper.mapGetters(['relatedModuleLogoAvailable']),
  },
});
