












import Vue from 'vue';
import { defineComponent, PropType } from 'vue';

import { decimal, required } from 'src/validators/validators';
import i18n from 'src/translate/lang';

import ListInput from './list-input.vue';

export default defineComponent({
  name: 'ListInputNumber',
  components: { ListInput },
  props: {
    required: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    showError: Boolean as PropType<boolean>,
    value: {
      type: null,
    },
    label: {
      type: String as PropType<string>,
      default: () => i18n.t('global.fields.number.label'),
    },
    placeholder: {
      type: String as PropType<string>,
      default: () => i18n.t('global.fields.number.placeholder'),
    },
  },
  data: () => ({
    validationParams: {
      decimal,
      $autoDirty: true,
    } as any,
  }),
  watch: {
    required: {
      handler(value: boolean) {
        if (value) {
          this.validationParams.required = required;
        } else {
          Vue.delete(this.validationParams, 'required');
        }
      },
      immediate: true,
    },
  },
});
