











import { defineComponent, PropType } from 'vue';

import { appStoreMapper } from '@/store/appstore';
import { getLocalizedPrice, formatNumber } from '@/utils';

import BonusIcon from 'src/components/bonus-icon.vue';

export default defineComponent({
  name: 'BonusBadge',
  components: { BonusIcon },
  props: {
    bonus: Number as PropType<number>,
    price: Number as PropType<number>,
    label: String as PropType<string>,
    options: Object as PropType<IDecimalOptions>,
  },
  computed: {
    formattedNumber(): Nullable<string> {
      if (this.label != null || (this.bonus == null && this.price == null)) {
        return null;
      } else {
        if (this.bonus != null) {
          return formatNumber(
            this.bonus,
            this.options?.decimalRoundingRule,
            this.options?.decimal,
          );
        } else if (this.price) {
          return getLocalizedPrice(
            this.price,
            this.currency,
            this.options?.decimalRoundingRule,
            this.options?.decimal,
          );
        }
      }
    },
    ...appStoreMapper.mapGetters(['currency']),
  },
});
