import { IBranch } from '@/types';
import { ISuggestion } from '../api';
import { ICheckAddressIsInDeliveryZoneReturn, IModuleSettings } from '../types';

export function checkAddressIsInDeliveryZone({
  googleMaps,
  map,
  mapShapes,
  suggestion,
  branches,
  settings,
}: {
  googleMaps: any;
  map: any;
  mapShapes: Map<any, any>;
  suggestion: ISuggestion;
  branches: IBranch[];
  settings: IModuleSettings;
}): ICheckAddressIsInDeliveryZoneReturn {
  const result: ICheckAddressIsInDeliveryZoneReturn = {
    inZone: false,
  };

  if (!map || !suggestion?.data?.geo_lat || !suggestion?.data?.geo_lon) {
    result.error = 'Map object or geocoder lan/lng does not exist';
    result.inZone = !settings.delivery?.checkZoneRequired;

    return result;
  }

  const point = new window.google.maps.LatLng(
    suggestion.data.geo_lat,
    suggestion.data.geo_lon,
  );

  mapShapes.clear();

  try {
    for (const branch of branches) {
      if (!branch.lat || !branch.lng || !branch.shape) continue;

      switch (branch.shape.type) {
        case 'circle':
          if (!mapShapes.has(branch)) {
            const center = new googleMaps.LatLng(
              branch.shape.center.lat,
              branch.shape.center.lng,
            );
            const distance = googleMaps.geometry.spherical.computeDistanceBetween(
              point,
              center,
            );

            if (distance <= branch.shape.radius) {
              mapShapes.set(branch, distance);
            }
          }
          break;
        case 'polygon':
          if (!mapShapes.has(branch)) {
            const center = new googleMaps.LatLng(branch.lat, branch.lng);
            const shape = new googleMaps.Polygon({
              map,
              paths: branch.shape.paths,
              fillOpacity: 0,
              strokeOpacity: 0,
            });

            if (googleMaps.geometry.poly.containsLocation(point, shape)) {
              const distance = googleMaps.geometry.spherical.computeDistanceBetween(
                point,
                center,
              );

              mapShapes.set(branch, distance);
            }
          }
          break;
      }
    }

    result.inZone = mapShapes.size > 0;

    if (result.inZone) {
      let shortestDistance: number = 0;
      mapShapes.forEach((distance, branch) => {
        if (!shortestDistance || distance < shortestDistance) {
          shortestDistance = distance;
          result.branch = branch;
        }
      });
    }
  } catch (e) {
    result.error = `Google maps API failed: ${e}`;
    result.inZone = !settings.delivery?.checkZoneRequired;
  }

  return result;
}
