


















































































import Vue, { PropType } from 'vue';
import { debounce } from 'lodash';

import {
  AddressDetails,
  DeliveryAddress,
  ICatalogDeliveryAddressCheck,
  ReceiveType,
} from '../types';
import bus from '@/bus';
import { appStoreMapper } from '@/store/appstore';
import { catalogStoreMapper } from '@/modules/catalog/dist/store';
import { getGeocoderSuggestions } from '@/api';
import { ISuggestion } from '../api';
import { checkAddressIsInDeliveryZone } from '../utils/checkAddressIsInDeliveryZone';
import { buildGeocoderValue } from '@/utils';

import DeliveryMapAddressForm from './DeliveryMapAddressForm.vue';
import ListInputText from '@/components/fields/list-input-text.vue';

export default Vue.extend({
  name: 'DeliveryMapSheetAddress',
  components: { DeliveryMapAddressForm, ListInputText },
  props: {
    address: String as PropType<string>,
    stepOpened: Boolean as PropType<boolean>,
    map: Object as PropType<Nullable<any>>,
  },
  data: () => ({
    innerAddress: '',
    geocoderLoading: false,
    inDeliveryZone: true,
    suggestionsList: [] as Suggest[],
    suggestion: null as Nullable<DeliveryAddress>,
    suggestionBranchId: null as Nullable<number | string>,
    zoneCheckFailed: false,
    mapShapes: new Map(),
  }),
  created() {
    bus.$on('catalog:delivery:address:sheet-open', () => {
      (this.$refs.address as any)?.f7Sheet.open();
    });

    bus.$on('catalog:delivery:address:sheet-close', () => {
      (this.$refs.address as any)?.f7Sheet.close();
    });

    bus.$on('catalog:delivery:address:step-open', () => {
      (this.$refs.address as any)?.f7Sheet.stepOpen();
    });

    bus.$on('catalog:delivery:address:step-close', () => {
      (this.$refs.address as any)?.f7Sheet.stepClose();
    });

    bus.$on('catalog:delivery:address:input-focus', () => {
      (this.$refs.addressInput as HTMLElement)?.querySelector('input')?.focus();
    });

    bus.$on(
      'catalog:delivery:address:check',
      ({ coords, suggest }: ICatalogDeliveryAddressCheck) => {
        const firstSuggest = suggest[0];
        this.onSuggestionClick({
          ...firstSuggest,
          ...{
            id: 0,
            data: {
              lat: coords.lat.toString(),
              lng: coords.lng.toString(),
            },
            value: firstSuggest.value || '',
            type: firstSuggest.type || 'address',
          },
        });

        // this.onSuggestionClick({
        //   id: 0,
        //   type: 'address',
        //   data: {
        //     lat: coords.lat.toString(),
        //     lng: coords.lng.toString(),
        //   },
        //   value: suggest.value,
        //   isExactAddress: suggest.isExactAddress,
        // });

        setTimeout(() => {
          this.onFocus();
        }, 100);
      },
    );
  },
  mounted() {
    this.innerAddress = this.address;

    ((this.$refs.address as unknown as Vue).$el as HTMLElement).addEventListener(
      'touchstart',
      (e: any) => {
        e.stopPropagation();
      },
    );

    ((this.$refs.address as unknown as Vue).$el as HTMLElement).addEventListener(
      'touchmove',
      (e: any) => {
        e.stopPropagation();
      },
    );

    window.addEventListener('keyboardHeightWillChange', () => {
      (this.$refs.address as any).f7Sheet.setSwipeStep();
    });

    if (this.deliveryAddress) {
      this.innerAddress = this.deliveryAddress.value;
      this.suggestion = this.deliveryAddress;
    }
  },
  computed: {
    checkZoneErrorText(): string {
      return this.address &&
        this.checkZoneEnabled &&
        !this.inDeliveryZone &&
        this.suggestion
        ? this.zoneCheckFailed
          ? 'Ошибка проверки зоны доставки'
          : 'Ваш адрес не входит в зону доставки'
        : '';
    },
    errorText(): string {
      return !!this.suggestion &&
        this.suggestion.id === 0 &&
        !this.suggestion.isExactAddress
        ? 'Не удалось определить адрес.<br/>Пожалуйста, уточните адрес вручную.'
        : '';
    },
    ...appStoreMapper.mapGetters(['pickupPoints']),
    ...appStoreMapper.mapState(['domain']),
    ...catalogStoreMapper.mapGetters([
      'settings',
      'singleReceiveTypeAvailable',
      'checkZoneEnabled',
    ]),
    ...catalogStoreMapper.mapState([
      'receive',
      'deliveryAddress',
      'deliveryAddressBranch',
    ]),
  },
  methods: {
    onAddressConfirm(details: AddressDetails) {
      this.setDeliveryAddress({
        suggestion: this.suggestion,
        details,
        branch: this.suggestionBranchId,
      });
      this.setReceive(ReceiveType.delivery);

      (this.$refs.address as any).f7Sheet.stepClose();
      this.$emit('update:stepOpened', false);
      bus.$emit('catalog:delivery:map:sheets-initialize');
    },
    async onSuggestionClick(suggestion: DeliveryAddress) {
      this.suggestion = suggestion;
      this.innerAddress = suggestion.value;

      // if (this.suggestion.id) {
      //   this.setDeliveryAddress({ suggestion: null, details: null, branch: null });
      // } else {
      this.$emit('update:step-opened', true);
      bus.$emit('catalog:delivery:address:step-open');
      bus.$emit('catalog:delivery:selected:sheet-close');
      bus.$emit('catalog:delivery:address:sheet-open');
      // }

      if (this.suggestion.id) {
        bus.$emit('catalog:delivery:map:set-center', {
          lat: this.suggestion.data.lat,
          lng: this.suggestion.data.lng,
          zoom: 16,
        });
      }

      if (this.checkZoneEnabled) {
        this.zoneCheckFailed = false;

        // try {
        //   this.geocoderLoading = true;
        //
        //   this.suggestionBranchId = await getDeliveryBranch(suggestion.data).idBranch;
        //
        //   this.inDeliveryZone = true;
        // } catch {
        //   this.zoneCheckFailed = true;
        // } finally {
        //   this.geocoderLoading = false;
        // }

        const sugg: ISuggestion = {
          data: {
            geo_lat: this.suggestion.data.lat,
            geo_lon: this.suggestion.data.lng,
          },
          unrestricted_value: this.suggestion.value,
          value: this.suggestion.value,
        };

        const { inZone, branch, error } = checkAddressIsInDeliveryZone({
          googleMaps: window.google.maps,
          map: this.map,
          mapShapes: this.mapShapes,
          suggestion: sugg,
          branches: this.pickupPoints,
          settings: this.settings,
        });

        this.zoneCheckFailed = !!error;
        this.inDeliveryZone = inZone;
        this.suggestionBranchId = branch?.idBranch;
      }
    },
    getAddress: debounce(function (this: any) {
      if (!this.geocoderLoading) {
        if (this.address.length >= 3) {
          this.geocoderLoading = true;

          getGeocoderSuggestions({
            query: this.address,
            settings: this.settings.geocoder,
          })
            .then(({ suggest, buildFormat }) => {
              const transformedSuggests: Suggest[] = [];
              suggest.forEach((suggestion) => {
                const templates: Nullable<GeocoderBuildTemplates> =
                  this.settings.geocoderSuggestTemplates || suggestion.templates;
                transformedSuggests.push({
                  ...suggestion,
                  ...buildGeocoderValue(suggestion, {
                    format: buildFormat,
                    templates,
                  }),
                });
              });
              this.suggestionsList = transformedSuggests;
            })
            .finally(() => {
              this.geocoderLoading = false;
            });
        } else {
          this.suggestionsList = [];
        }
      }
    }, 300),
    onKeyUp() {
      if (!this.stepOpened && this.address.length) {
        this.$emit('update:step-opened', true);
        (this.$refs.address as any).f7Sheet.stepOpen();
      }
    },
    onFocus() {
      this.$emit('update:step-opened', true);
      (this.$refs.address as any).f7Sheet.stepOpen();
    },
    onAddressCloseClock() {
      this.$emit('update:step-opened', false);
      bus.$emit('catalog:delivery:address:step-close');
      bus.$emit('catalog:delivery:map:sheets-initialize');
    },
    ...catalogStoreMapper.mapActions(['setDeliveryAddress']),
    ...catalogStoreMapper.mapMutations(['setReceive']),
  },
  watch: {
    address(value: string, prev: string) {
      if (value !== prev) {
        this.innerAddress = this.address;
      }
    },
    innerAddress(value: string) {
      this.$emit('update:address', this.innerAddress);

      if (value !== this.suggestion?.value && this.stepOpened) {
        this.suggestion = null;
        this.suggestionBranchId = null;
        this.getAddress();

        if (this.checkZoneEnabled) {
          this.inDeliveryZone = false;
        }
      }
    },
    stepOpened() {
      if (!this.stepOpened) {
        this.innerAddress = this.deliveryAddress?.value || '';
        this.suggestion = this.deliveryAddress || null;

        this.suggestionsList = [];
        this.suggestionBranchId = null;
        this.zoneCheckFailed = false;

        this.inDeliveryZone = true;
      } else {
        this.suggestionBranchId = this.deliveryAddressBranch;
      }
    },
    receive() {
      this.innerAddress = this.deliveryAddress?.value || '';
      this.suggestionsList = [];
      this.suggestionBranchId = null;
      this.zoneCheckFailed = false;
    },
    domain() {
      this.innerAddress = this.deliveryAddress?.value || '';
      this.suggestion = this.deliveryAddress || null;

      this.suggestionsList = [];
      this.suggestionBranchId = null;
      this.zoneCheckFailed = false;
    },
  },
});
