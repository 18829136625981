
















































































import Vue, { PropType } from 'vue';
import { f7 } from 'framework7-vue';

import { getLocalizedPrice } from '@/utils';

import { appStoreMapper } from '@/store/appstore';
import { bonusStoreMapper } from '../store';

export default Vue.extend({
  name: 'BonusProgress',
  props: {
    location: {
      type: String as PropType<'home' | 'popup'>,
      required: true,
    },
  },
  computed: {
    show(): boolean {
      return this.settings.groups.length > 1;
    },
    orderSumPercent(): number {
      if (!this.user) return 0;
      if (this.settings.groups.length > 1 && !this.nextGroup) return 100;
      if (!this.nextGroup) return 0;

      return Math.floor((this.user.orderSum / this.nextGroup.orderSum) * 100);
    },
    nextGroupOrderSum(): string {
      if (!this.nextGroup) {
        return '0';
      }

      return getLocalizedPrice(
        this.nextGroup.orderSum,
        this.currency,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
    orderSum(): string {
      if (!this.user) {
        return '0';
      }

      return getLocalizedPrice(
        this.user.orderSum,
        this.currency,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
    currentGroupTitle(): string {
      return this.currentGroup?.name || '';
    },
    currentGroupBonus(): string {
      return `${this.currentGroup?.bonus}%`;
    },
    nextGroupTitle(): string {
      return this.nextGroup?.name || '';
    },
    nextGroupBonus(): string {
      return `${this.nextGroup?.bonus}%`;
    },
    nextGroupAmount(): string {
      if (!this.user || !this.nextGroup) {
        return '0';
      }

      return getLocalizedPrice(
        this.nextGroup.orderSum - this.user.orderSum,
        this.currency,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
    ...appStoreMapper.mapGetters(['user', 'decimalOptions', 'currency']),
    ...bonusStoreMapper.mapGetters(['settings', 'nextGroup', 'currentGroup']),
  },
  methods: {
    onClick() {
      if (this.location !== 'home') {
        return false;
      }

      f7.popup.open('.groups-popup');
    },
  },
});
