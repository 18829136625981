















































import Vue, { PropType } from 'vue';
import { f7 } from 'framework7-vue';

import { ICatalogHistoryItem, OrderStatus } from '../types';

import { appStoreMapper } from '@/store/appstore';
import { catalogStoreMapper } from '@/modules/catalog/dist/store';
import { timeZone, getLocalizedPrice } from '@/utils';

export default Vue.extend({
  name: 'HistoryItem',
  props: {
    item: {
      type: Object as PropType<ICatalogHistoryItem>,
      required: true,
    },
  },
  data: () => ({
    OrderStatus,
  }),
  computed: {
    date(): string {
      if (Number.isNaN(new Date(this.item.orderDate).getDate())) {
        return '—';
      }

      return timeZone(this.item.orderDate, {
        locale: this.locale,
        type: 'datetimeLong',
        timezone: this.timeZoneOptions,
      });
    },
    orderStatusLabel(): string {
      return this.$t(
        'modules.catalog.history.status.' + this.item.orderStatus,
      ).toString();
    },
    formattedPrice(): string {
      return getLocalizedPrice(
        this.item.toPay,
        this.currency,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'timeZoneOptions', 'currency']),
    ...appStoreMapper.mapState(['locale']),
  },
  methods: {
    onItemClick() {
      this.setSelectedHistoryItem(this.item);
      f7.views.current.router.navigate('/catalog/history/body');
    },
    ...catalogStoreMapper.mapMutations(['setSelectedHistoryItem']),
  },
});
