

























































import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { f7 } from 'framework7-vue';

import { ReceiveType } from '@/modules/catalog/dist/types';

import { useAppStore } from '@/store/appstore';
import { useCatalogStore } from '../store';
import bus from '@/bus';
import { formatNumber } from '@/utils';
import getLocalizedPrice from '@/utils/getLocalizedPrice';

import AnimatedNumber from '@/components/AnimatedNumber.vue';
import BonusIcon from 'src/components/bonus-icon.vue';
import CatalogBarWrapper from './CatalogBarWrapper.vue';
import FieldInput from '@/components/FieldInput.vue';

function useInput() {
  const appStore = useAppStore();
  const catalogStore = useCatalogStore();

  const amount = ref<number>(0);
  const innerAmount = ref<string>('');

  const decimal = computed(() => appStore.getters.decimalOptions.decimal > 0);

  function onContinueClicked() {
    f7.views.current.router.navigate('/catalog/form');
  }

  onMounted(() => {
    bus.$on('catalog:bonus-confirm', onContinueClicked);

    watch(
      () => amount.value,
      () => {
        bus.$emit('catalog:total-bonus', amount.value);
        catalogStore.commit('setPayByBonusOrderAmount', amount.value);
      },
      {
        immediate: true,
      },
    );

    watch(
      () => innerAmount.value,
      (value: string, prevValue: string) => {
        if (value === prevValue) return;

        let val = value.replace(/[^0-9]/g, '');

        if (appStore.getters.decimalOptions.decimal > 0) {
          val = value.replace(/[^0-9.,]/g, '').replace(/,/g, '.');

          const firstDotIndex = val.indexOf('.');
          if (firstDotIndex >= 0) {
            const beforeFirstDot = val.slice(0, firstDotIndex + 1);
            const afterFirstDot = val.slice(firstDotIndex + 1);
            const removeDots = afterFirstDot.replace(/\./g, '');
            val = beforeFirstDot + removeDots;
          }
        }
        let number = parseFloat(val) || 0;

        if (number > catalogStore.getters.bonusPayOrderMaxAmount) {
          number = parseFloat(
            formatNumber(
              catalogStore.getters.bonusPayOrderMaxAmount,
              appStore.getters.decimalOptions.decimalRoundingRule,
              appStore.getters.decimalOptions.decimal,
              { separator: '' },
            ),
          );
          val = number.toString();
        } else if (number < 0) {
          number = 0;
          val = '';
        }

        if (appStore.getters.decimalOptions.decimal > 0) {
          const parts = val.split('.');
          if (parts[1] && parts[1].length > appStore.getters.decimalOptions.decimal) {
            val = `${parts[0]}.${parts[1].substr(
              0,
              appStore.getters.decimalOptions.decimal,
            )}`;
            number = parseFloat(val);
          }
        }

        innerAmount.value = val;
        amount.value = number;
      },
    );
  });

  return {
    decimal,
    amount,
    innerAmount,
    bonusPayOrderMaxAmount: catalogStore.getters.bonusPayOrderMaxAmount,
  };
}

function useBarControls() {
  const catalogStore = useCatalogStore();

  function onPageBeforeIn() {
    catalogStore.commit('setInBonus', true);
  }

  function onPageBeforeOut() {
    catalogStore.commit('setInBonus', false);
  }

  return {
    onPageBeforeIn,
    onPageBeforeOut,
  };
}

export default defineComponent({
  name: 'BonusPayOrder',
  methods: { formatNumber },
  components: {
    AnimatedNumber,
    BonusIcon,
    CatalogBarWrapper,
    FieldInput,
  },
  setup() {
    const appStore = useAppStore();
    const catalogStore = useCatalogStore();
    const inputElement = useInput();
    const barControls = useBarControls();

    const miniappHideElements = appStore.getters.miniappHideElements;

    const totalProductsPriceComputed = computed<number>(() => {
      switch (catalogStore.state.receive) {
        case ReceiveType.delivery:
          return catalogStore.getters.totalPriceDelivery;
        case ReceiveType.pickup:
          return catalogStore.getters.totalPricePickup;
        default:
          return catalogStore.getters.totalProductsPrice;
      }
    });

    const resultSum = computed<number>(() => {
      const sum = totalProductsPriceComputed.value - inputElement.amount.value;

      if (appStore.getters.decimalOptions.decimal != null) {
        return sum;
      } else {
        return Math.round(sum);
      }
    });

    function localizePrice(value: number): string {
      return getLocalizedPrice(
        value,
        appStore.getters.currency,
        appStore.getters.decimalOptions.decimalRoundingRule,
        appStore.getters.decimalOptions.decimal,
        {
          useMils: true,
        },
      );
    }

    function formatPriceValue(value: number): string {
      if (value == null) {
        return getLocalizedPrice(value, appStore.getters.currency);
      }

      if (appStore.getters.decimalOptions.decimal == null) {
        return localizePrice(Math.round(value));
      }

      return localizePrice(value);
    }

    return {
      resultSum,
      formatPriceValue,
      miniappHideElements,
      ...barControls,
      ...inputElement,
    };
  },
});
