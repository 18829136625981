

















































import { AuthType } from 'src/types/enum';

import { defineComponent, PropType } from 'vue';
import { IMaskComponent } from 'vue-imask';

import { checkUserExists } from '../api';
import { showErrorDialog } from 'src/misc';
import { authStoreMapper } from '../store';

export default defineComponent({
  components: { IMaskComponent },
  props: {
    openProfile: Boolean as PropType<boolean>,
    popup: Boolean as PropType<boolean>,
  },
  data: () => ({
    phone: '+',
    error: '',
    selectionRangeAttemptsCount: 0,
    repeatTimeout: 15,
    repeatTimeoutCurrent: 0,
    mask: [
      {
        mask: '+0000000000000',
        startsWith: '+',
        country: 'unknown',
      },
      {
        mask: '+ {7} (000) 000-00-00',
        startsWith: '+7',
        country: 'Russian Federation',
      },
      {
        mask: '+ {375} 00 000-00-00',
        startsWith: '+375',
        country: 'Russian Federation',
      },
    ],
  }),
  mounted() {
    if (document.documentElement.classList.contains('with-modal-popup')) {
      this.$f7?.popup.close();
    }

    if (document.documentElement.classList.contains('with-modal-sheet')) {
      this.$f7?.sheet.close();
    }

    if (this.popup) {
      setTimeout(() => {
        this.pageAfterIn();
      }, 500);
    }
  },
  computed: {
    formattedPhone(): string {
      return this.phone.replace(/\D/g, '');
    },
    ...authStoreMapper.mapGetters(['settings']),
  },
  methods: {
    dispatch(appended: string, dynamicMasked: any) {
      let maskIndex = 0;

      if (dynamicMasked.unmaskedValue.startsWith('7') && dynamicMasked.value.length > 4) {
        maskIndex = 1;
      } else if (
        dynamicMasked.unmaskedValue.startsWith('375') &&
        dynamicMasked.value.length > 4
      ) {
        maskIndex = 2;
      }

      if (maskIndex > 0) {
        this.setFocusToEnd();

        return dynamicMasked.compiledMasks[maskIndex];
      }

      return dynamicMasked.compiledMasks[maskIndex];
    },
    checkPhone() {
      if (this.formattedPhone.length < 11) {
        this.error = this.$t('modules.auth.signin.badphone') as string;

        return;
      }

      this.$refs.phone && (this.$refs.phone as any).$el.blur();

      this.$f7?.preloader.show();

      checkUserExists({ phone: this.formattedPhone })
        .then((response) => {
          if (response.exists) {
            if (this.settings.usePincode) {
              this.$f7router?.navigate('/auth/pin', {
                props: {
                  phone: this.formattedPhone,
                  openProfile: this.openProfile,
                },
              });
            } else if (this.settings.type === AuthType.base) {
              this.$f7router?.navigate('/auth/password', {
                props: {
                  phone: this.formattedPhone,
                  openProfile: this.openProfile,
                },
              });
            } else if (
              this.settings.type === AuthType.sms ||
              this.settings.type === AuthType.call
            ) {
              this.$f7router?.navigate('/auth/pincode', {
                props: {
                  phone: this.formattedPhone,
                  openProfile: this.openProfile,
                },
              });
            }
          } else {
            this.$f7router?.navigate('/registration', {
              props: { username: this.formattedPhone },
            });
          }
        })
        .catch((error) => {
          const text = error.message || error.error || error.name;
          let interval: any = null;

          if (text === 'Network Error') {
            const dialog = showErrorDialog.call(this, {
              buttons: [
                {
                  text: this.$t('global.dialog.repeat') + ` (${this.repeatTimeout})`,
                  onClick: () => {
                    clearInterval(interval);

                    this.checkPhone();
                  },
                },
              ],
              callbacks: {
                onClose: () => {
                  clearInterval(interval);
                },
              },
              closeButtonText: this.$t('global.dialog.ok').toString(),
              error,
            });

            this.repeatTimeoutCurrent = this.repeatTimeout;

            const button = dialog.el.querySelector(
              '.dialog-button:first-child',
            ) as HTMLElement;

            if (button) {
              button.classList.add('disabled');

              interval = setInterval(() => {
                if (this.repeatTimeoutCurrent <= 0) {
                  clearInterval(interval);
                }

                if (this.repeatTimeoutCurrent > 0) {
                  button.innerHTML =
                    this.$t('global.dialog.repeat') + ` (${this.repeatTimeoutCurrent})`;
                } else {
                  button.classList.remove('disabled');
                  button.innerHTML = this.$t('global.dialog.repeat').toString();
                }

                this.repeatTimeoutCurrent--;
              }, 1000);
            }
          } else {
            showErrorDialog.call(this, { error });
          }
        })
        .finally(() => {
          this.$f7?.preloader.hide();
        });
    },
    pageAfterIn() {
      ((this.$refs.phone as unknown as Vue).$el as HTMLInputElement).focus();
    },
    setFocusToEnd() {
      const inputEl = (this.$refs.phone as unknown as Vue).$el as HTMLInputElement;
      const length = this.phone.length;

      inputEl.focus();

      // Не всегда срабатывает, а привязаться не к чему
      if (this.selectionRangeAttemptsCount < 5) {
        this.selectionRangeAttemptsCount++;

        inputEl.setSelectionRange(length, length);

        setTimeout(() => {
          this.setFocusToEnd();
        }, 10);
      } else {
        this.selectionRangeAttemptsCount = 0;
      }
    },
    onPopupCloseClick() {
      this.$emit('close');
    },
  },
  watch: {
    phone() {
      if (this.phone.startsWith('+8') && !this.settings.disablePhoneCodeCheck) {
        this.phone = '+7' + this.phone.substring(2);
      }

      if (this.formattedPhone.length === 11) {
        this.error = '';
      }
    },
  },
});
