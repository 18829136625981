export type idGift = number & { readonly brand?: unique symbol };

import { TResponse } from 'src/api';

export interface Gift {
  id: idGift;
  idBranch: number;
  code: string;
  codeText?: string;
  codeQR?: string;
  title: string;
  description: string;
  icon: number;
  background: number;
  isOneTime: boolean;
  price: number;
  label?: string;
}

export interface IGetGiftResponse extends TResponse {
  gift: Gift[];
}

export interface IGetCouponResponse extends TResponse {
  coupon: Coupon[];
}

export interface IModuleSettings {
  title?: string;
  tabbarTitle?: string;
  openInPopup?: boolean;
  type?: GiftsListType;
  list?: Gift[];
  itemsPerPage?: number;
}

export enum GiftsListType {
  list = 'list',
  swiper = 'swiper',
}

export type Coupon = Gift;

export type CouponWithType = Coupon & { type: GiftType };
export type GiftWithType = Gift & { type: GiftType };

export interface IGetCouponsResponse extends TResponse {
  gift: Gift[];
  coupon: Coupon[];
  items: string[];
}

export type GiftType = 'gift' | 'coupon';
