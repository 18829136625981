























import { defineComponent } from 'vue';
import { f7 } from 'framework7-vue';

import bus from 'src/bus';
import { feedbackStoreMapper } from '../store';

import FeedbackForm from './feedback-form.vue';

export default defineComponent({
  name: 'FeedbackPopup',
  components: { FeedbackForm },
  data: () => ({
    entityId: null as Nullable<number>,
    sheetHeight: 0,
  }),
  created() {
    bus.$on('feedback:sheet:show', (idEntity: number) => {
      this.entityId = idEntity;
      f7.sheet.open('.feedback-sheet');
    });

    bus.$on('feedback:sheet:hide', (idEntity: number) => {
      f7.sheet.close('.feedback-sheet');
    });

    bus.$on('messageClosed', () => {
      (this.$refs.sheet as any).close();
    });
  },
  computed: {
    ...feedbackStoreMapper.mapGetters(['moduleIsActive']),
  },
  methods: {
    onSheetClose() {
      bus.$emit('hideMessage');
    },
    onSheetClosed() {
      (this.$refs.form as any)?.resetState();
    },
    onSheetOpened() {
      this.sheetHeight = (
        (this.$refs.sheet as unknown as Vue).$el as HTMLElement
      ).offsetHeight;
    },
  },
});
