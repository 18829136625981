
























import Vue, { PropType } from 'vue';

import { IProduct, ProductPropItem } from '../types';

export default Vue.extend({
  name: 'ProductProps',
  props: {
    product: {
      type: Object as PropType<IProduct>,
      required: true,
    },
  },
  computed: {
    showProps(): boolean {
      return !!this.product.params?.length;
    },
    bju(): any[] {
      return (
        this.product.params?.find((group) => group.group === 'composition')?.items || []
      );
    },
    BJUisIncomplete(): boolean {
      return this.bju.length < 4;
    },
    weight(): ProductPropItem[] {
      return this.product.params?.find((group) => group.group === 'weight')?.items || [];
    },
    size(): ProductPropItem[] {
      return this.product.params?.find((group) => group.group === 'size')?.items || [];
    },
    showWeightSizeBlock(): boolean {
      return !!this.weight.length || !!this.size.length;
    },
  },
});
