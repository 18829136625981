import { TResponse } from 'src/api';
import { IBranch } from '@/types';

export interface ICategory {
  id: number;
  name: string;
  photo: number;
  description?: string;
  label?: string;
  sub_categories: ICategory[];
  badges?: ItemBadge[];
}

export type ProductAmount = {
  branch_id: number;
  amount: number;
};

export type ProductAttributeValue = {
  id: number;
  name: string;
  selected: boolean;
  disabled: boolean;
};

export type ProductAttribute = {
  id: number;
  name: string;
  values: ProductAttributeValue[];
};

export type ProductAttributeForCheck = {
  attributeId: number;
  attributeName: string;
  valueId: number;
  valueName: string;
};

export type AllowBasket = 'all' | 'off' | 'onlyDelivery' | 'onlyPickup';

export interface IProduct {
  id: number;
  name: string;
  price: number;
  priceRange?: [number, number];
  images: number[];
  description: string;
  category_id: number;
  active: boolean;
  amounts?: ProductAmount[];
  label?: string;
  deleted?: boolean;
  badges?: ItemBadge[];
  params?: ProductPropGroup[];
  maxPayBonus: number;
  showPrice?: boolean;
  productAttributeValues: ProductAttribute[];
  isVariation: boolean;
  parentId: Nullable<number>;
  allowBasket: Nullable<AllowBasket>;
}

export enum IconType {
  catalog = 'catalog',
  delivery = 'delivery',
}

export interface IPaymentType {
  id: number;
  name: string;
  plugin: Nullable<string>;
  orderButtonName?: string;
  isActive?: boolean;
  needEmail: boolean;
  dependDelivery: 'all' | 'delivery' | 'pickup';
}

export interface IModuleSettings {
  tab?: boolean;
  title?: string;
  tabbarTitle?: string;
  homeSlotTitle?: string;
  icon?: IconType;
  showBasket?: boolean;
  delivery?: {
    start?: string;
    end?: string;
    days?: string[];
    deliveryPrice: number;
    forFreeDelivery?: number;
    minOrder?: number;
    paymentTypes?: payType[];
    checkZone?: boolean;
    checkZoneRequired?: boolean;
    askTime?: boolean;
    timeStep?: number;
    afterStartTimeGap?: number;
    beforeEndTimeGap?: number;
    closedText?: string;
  };
  list: ICategory[];
  payByBonus: boolean;
  maxPayBonusK: number;
  minPaymentAfterBonus: number;
  payByBonusType: 'product' | 'order';
  pickup?: {
    minOrder?: number;
    discount?: number;
    askTime?: boolean;
    timeStep?: number;
    afterStartTimeGap?: number;
    beforeEndTimeGap?: number;
    closedText?: string;
  };
  historyTitle?: string;
  historyItemsPerPage?: number;
  historyRepeat?: boolean;
  blockCheckout?: boolean;
  paymentTypeList?: IPaymentType[];
  hideRootTitles?: boolean;
  showBarOnHomeScreen?: boolean;
  finishMessageTitle?: string;
  finishMessageText?: string;
  geocoder?: {
    [key: string]: any;
  };
  geocoderSuggestTemplates?: GeocoderBuildTemplates;
  geocoderAddressTemplates?: GeocoderBuildTemplates;
  amountControl: boolean;
  variationsSwipe?: boolean;
  showOpeningHoursInfo?: boolean;
}

export interface IBasketEntry {
  product: IProduct;
  amount: number;
}

export interface ISendOrderRequest {
  order: {
    address?: string;
    comment?: string;
    name: string;
    phone: string;
    products: IOrderProduct[];
    receiveType?: ReceiveType;
    toBranch?: string | number;
    room?: string;
    domofon?: string;
    entrance?: string;
    floor?: string;
    lat?: string;
    lng?: string;
    paymentType?: number;
    email?: Nullable<string>;
    paidByBonus?: number;
  };
}

export interface IOrderProduct {
  count: number;
  paidByBonus?: number;
  product_id: number;
}

export interface IAdditionalActionPayment {
  type: 'payment';
  payment: IPaymentType;
  params: {
    url: string;
    successUrl: string;
    failUrl?: string;
  };
}

export interface ISendOrderResponse extends TResponse {
  additionalAction?: IAdditionalActionPayment;
}

export enum CatalogListType {
  list = 'list',
  swiper = 'swiper',
}

export enum ReceiveType {
  pickup = 'pickup',
  delivery = 'delivery',
}

export enum payType {
  online = 'online',
  transfer = 'transfer',
  cash = 'cash',
}

export enum OrderStatus {
  'new' = 'new',
  'process' = 'process',
  'done' = 'done',
  'cancel' = 'cancel',
}

export interface ICatalogHistoryItemProduct {
  cost: number;
  id: number;
  images: Array<number | string> | ['0'];
  name: string;
  qty: number;
  productAttributeValues: ProductAttributeForCheck[];
}

export interface ICatalogHistoryItem {
  id: number;
  orderDate: string;
  sumCostProducts: number;
  payedBonuses: number;
  costDelivery: number;
  toPay: number;
  orderNum: string;
  status: OrderStatus;
  deliveryAddress: string;
  idPickupBranch: number;
  receiveType: Nullable<ReceiveType>;
  payType: Nullable<payType>;
  addedBonuses: number;
  products: Nullable<ICatalogHistoryItemProduct[]>;
  discount: number;
  paymentType: Nullable<IPaymentType>;
  paymentStatus: Nullable<'paid' | 'wait'>;
  repeatable?: boolean;
  comment: string;
  delivery: Nullable<{
    address: string;
    domofon: string;
    entrance: string;
    floor: string;
    lat: string;
    lng: string;
    room: string;
    toBranch: number;
  }>;
}

export interface ICheckAddressIsInDeliveryZoneReturn {
  inZone: boolean;
  branch?: IBranch;
  error?: string;
}

export type AddressDetails = {
  room: string;
  domofon: string;
  entrance: string;
  floor: string;
  comment: string;
};

export interface IGetDeliveryBranchRequest {
  lat: string | number;
  lng: string | number;
}

export interface IGetDeliveryBranchResponse extends TResponse {
  data: {
    idBranch: number;
  };
}

export type DeliveryAddress = {
  id: string | number;
  data: IMapCoordinates;
  value: string;
  type: AddressType;
  isExactAddress?: boolean;
};

export interface ICatalogDeliveryAddressCheck {
  coords: IMapCoordinates;
  suggest: Suggest[];
}

export type ProductPropItem = {
  label: string;
  value: string;
};

export type ProductPropGroup = {
  group: 'size' | 'weight' | 'composition';
  title?: string;
  items: ProductPropItem[];
};

export type CatalogSearch = {
  products: IProduct[];
  categories: ICategory[];
};

export type SearchCache = {
  cache: CatalogSearch;
  scrollTop: number;
  query: string;
};

export interface ICatalogSearchRequest {
  query: string;
  category?: number;
}

export interface ICatalogSearchResponse {
  catalogSearch: CatalogSearch;
}

export interface IGetProductVariationByAttributeValueRequest {
  id: number;
  attributeId: number;
  valueId: number;
  branch?: Nullable<string | number>;
}
