


















import { defineComponent } from 'vue';
import { f7ready, f7 } from 'framework7-vue';

import { ReceiveType } from '../types';

import bus from '@/bus';
import config from '../catalog.conf.json';
import { catalogStoreMapper } from '../store';
import { appStoreMapper } from '@/store/appstore';
import getLocalizedPrice from '@/utils/getLocalizedPrice';

import CatalogBarWrapper from './CatalogBarWrapper.vue';
import CatalogBasketBar from './CatalogBasketBar.vue';
import CatalogFormBar from './CatalogFormBar.vue';
import CatalogBonusPayBar from './CatalogBonusPayBar.vue';

export default defineComponent({
  name: 'CatalogTabbarSlot',
  components: { CatalogBarWrapper, CatalogBasketBar, CatalogFormBar, CatalogBonusPayBar },
  data: () => ({
    catalogRoute: false,
    currentRoutePath: '/',
    paymentTypeId: 0,
    totalBonus: 0,
  }),
  mounted() {
    f7ready(() => {
      setTimeout(() => {
        this.startRouteWatchers();
      }, 500);
    });

    bus.$on('catalog:form-payment-method-id', (id: number) => {
      this.paymentTypeId = id;
    });

    bus.$on('catalog:total-bonus', (total: number) => {
      this.totalBonus = total;
    });

    bus.$on('authAfterRegisterSuccess', () => {
      if (this.inBasket) {
        this.goToForm(true);
      }
    });

    bus.$on('authAfterLoginSuccess', () => {
      if (this.inBasket) {
        this.goToForm(true);
      }
    });
  },
  computed: {
    isVisible(): boolean {
      if (!(!!this.settings.showBasket && !!this.totalProducts)) return false;
      if (!(this.currentTab === 'home' || this.currentTab === config.name)) return false;
      if (
        this.catalogRoute &&
        (/\/history/.test(this.currentRoutePath) ||
          /\/pickup/.test(this.currentRoutePath))
      ) {
        return false;
      }
      if (
        (this.receive === ReceiveType.pickup && !this.pickupPoint) ||
        (this.receive === ReceiveType.delivery && !this.deliveryAddress)
      ) {
        return false;
      }

      return (
        this.catalogRoute ||
        (!!this.settings.showBarOnHomeScreen &&
          this.currentTab === 'home' &&
          this.currentRoutePath === '/') ||
        (this.currentTab === config.name && this.currentRoutePath === '/')
      );
    },
    inCatalog(): boolean {
      return this.currentTab === config.name || this.catalogRoute;
    },
    inHome(): boolean {
      return this.currentTab === 'home';
    },
    minimumProductPrice(): number {
      return (
        Math.min.apply(
          null,
          this.basket.map(
            (item) =>
              item.product.price *
              ((100 - this.discount) / 100) *
              (Math.min(item.product.maxPayBonus, this.settings.maxPayBonusK) / 100),
          ),
        ) || 0
      );
    },
    buttonDisabled(): boolean {
      return !this.totalProducts || this.totalProductsPrice - this.minOrder < 0;
    },
    getTotalProductsPriceComputed(): number {
      switch (this.receive) {
        case ReceiveType.delivery:
          return this.totalPriceDelivery - this.payByBonusOrderAmount;
        case ReceiveType.pickup:
          return this.totalPricePickup - this.payByBonusOrderAmount;
        default:
          return this.totalProductsPrice - this.payByBonusOrderAmount;
      }
    },
    resultPrice(): number {
      let price = this.getTotalProductsPriceComputed;

      if (this.inForm) {
        price -= this.totalPayedByBonus;
      }

      return price;
    },
    formButtonLabel(): Nullable<string> {
      let orderButtonName = this.settings.paymentTypeList?.find(
        (payment) => payment.id === this.paymentTypeId,
      )?.orderButtonName;

      if (orderButtonName) {
        if (this.resultPrice) {
          orderButtonName +=
            ' ' +
            getLocalizedPrice(
              this.resultPrice,
              this.currency,
              this.decimalOptions.decimalRoundingRule,
              this.decimalOptions.decimal,
            );
        } else {
          orderButtonName = this.$i18n
            .t('modules.catalog.bar.actions.withoutPay')
            .toString();
        }
      }

      return orderButtonName;
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'user', 'currency']),
    ...appStoreMapper.mapState(['currentTab', 'appData', 'domain']),
    ...catalogStoreMapper.mapGetters([
      'settings',
      'totalProducts',
      'totalProductsPrice',
      'notWorkingTime',
      'minOrder',
      'totalPriceDelivery',
      'totalPricePickup',
      'workingTimeLabel',
      'notActiveProductsList',
      'notForSaleProductsList',
      'receiveAvailable',
      'bonusPayOrderMaxAmount',
      'productsInBasketWithPayBonus',
      'totalPayedByBonus',
      'discount',
    ]),
    ...catalogStoreMapper.mapState([
      'inBasket',
      'inForm',
      'inBonus',
      'basket',
      'receive',
      'pickupPoint',
      'deliveryAddress',
      'paidByBonus',
      'payByBonusOrderAmount',
    ]),
  },
  methods: {
    onFormClick() {
      bus.$emit('catalog:form-confirm');
    },
    onBonusClick() {
      bus.$emit('catalog:bonus-confirm');
    },
    onClick() {
      if (this.inBasket) {
        this.goToForm();
      } else {
        this.goToBasket();
      }
    },
    goToBasket() {
      f7.views.current.router.navigate({ name: 'Basket' });
    },
    goToForm(force?: boolean) {
      if (this.notActiveProductsList.length || this.notForSaleProductsList.length) {
        const dialog = f7.dialog.create({
          buttons: [
            {
              text: this.$t('global.dialog.cancel').toString(),
              close: true,
            },
            {
              text: this.$t('global.dialog.yes').toString(),
              onClick: () => {
                this.removeNonActiveProductsFromBasket();
                this.removeNotForSaleProductsFromBasket();

                this.$nextTick(() => {
                  this.goToForm();
                });
              },
              bold: true,
              close: true,
            },
          ],
          text: this.$t('modules.catalog.basket.nonactive.dialog.text').toString(),
        });

        dialog && dialog.open();

        return false;
      }

      if (!this.appData.user?.idUser) {
        bus.$emit('needRegister:popup', {
          showDialog: false,
        });

        return false;
      }

      if (!this.receive && this.receiveAvailable) {
        f7.dialog.alert(
          this.$t('modules.catalog.basket.receive.text').toString(),
          this.$t('modules.catalog.basket.receive.title').toString(),
        );
      } else if (
        this.receive === ReceiveType.delivery &&
        this.settings.blockCheckout &&
        !!this.notWorkingTime
      ) {
        f7.dialog.alert(
          this.$t('modules.catalog.dialogs.deliveryNotAvailable.text').toString() +
            '<br/><br/>' +
            this.$t('modules.catalog.delivery.workingTime') +
            ':<br/>' +
            this.workingTimeLabel,
          this.$t('modules.catalog.dialogs.deliveryNotAvailable.title').toString(),
        );
      } else {
        if (
          this.settings.payByBonus &&
          this.user?.bonus &&
          this.productsInBasketWithPayBonus.length > 0
        ) {
          if (
            this.settings.payByBonusType === 'product' &&
            this.minimumProductPrice <= this.user.bonus
          ) {
            f7.views.current.router.navigate(
              { name: 'CatalogBonusProduct' },
              { animate: !force },
            );
          } else if (
            this.settings.payByBonusType === 'order' &&
            this.bonusPayOrderMaxAmount
          ) {
            f7.views.current.router.navigate(
              { name: 'CatalogBonusOrder' },
              { animate: !force },
            );
          } else {
            f7.views.current.router.navigate(
              { name: 'CatalogForm' },
              { animate: !force },
            );
          }
        } else {
          f7.views.current.router.navigate({ name: 'CatalogForm' }, { animate: !force });
        }
      }
    },
    startRouteWatchers() {
      this.$nextTick(() => {
        if (!f7?.views) {
          setTimeout(() => {
            this.startRouteWatchers();
          }, 100);
        } else {
          (f7.views as any)
            .filter(
              (view: any) =>
                view.selector.includes('#homeTab') ||
                view.selector.includes('#catalogTab'),
            )
            .forEach((view: any) => {
              let iteration = 0;
              let maxIterations = 20;
              const watchInterval = setInterval(() => {
                if (view.router?.currentRoute?.path) {
                  this.$watch(
                    () => view.router?.currentRoute,
                    (route: any, prev: any) => {
                      if (route && prev && route.path !== prev.path) {
                        this.catalogRoute = /catalog/.test(route.path);
                        this.currentRoutePath = route.path;
                      }
                    },
                    { deep: true },
                  );

                  clearInterval(watchInterval);
                }

                if (iteration >= maxIterations) {
                  clearInterval(watchInterval);
                }

                iteration++;
              }, 200);
            });
        }
      });
    },
    ...catalogStoreMapper.mapActions([
      'removeNonActiveProductsFromBasket',
      'removeNotForSaleProductsFromBasket',
    ]),
    ...catalogStoreMapper.mapMutations(['setPaidByBonus']),
  },
  watch: {
    currentTab() {
      this.catalogRoute = false;
      this.currentRoutePath = '/';
    },
    domain() {
      setTimeout(() => {
        this.startRouteWatchers();
      }, 500);
    },
    appData() {
      this.startRouteWatchers();
    },
    inBasket() {
      if (this.inBasket) {
        this.setPaidByBonus([]);
      }
    },
  },
});
