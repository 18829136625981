










































































import { defineComponent } from 'vue';
import { ContentLoader } from 'vue-content-loader';

import { IBranch } from '@/types';
import { GiftsListType, Gift, Coupon } from '../types';

import { appStoreMapper } from 'src/store/appstore';
import { giftsStoreMapper } from '../store';
import bus from '@/bus';
import config from '@/app.config.js';

import GiftsListEntrySlide from './gifts-list-entry-slide.vue';
import GiftsListEntryItem from './gifts-list-entry-item.vue';

export default defineComponent({
  name: 'GiftsHomeSlot',
  components: { GiftsListEntryItem, GiftsListEntrySlide, ContentLoader },
  data: () => ({
    GiftsListType,
    config,
    swiperParams: {
      slidesPerView: 'auto',
      spaceBetween: 15,
    } as any,
    itemsPerPage: 0,
    page: 1,
    createdDomain: '',
    isLoaded: false,
  }),
  created() {
    this.createdDomain = this.domain;
  },
  destroyed() {
    bus.$off('appDataDidLoad', this.onAppDataDidLoad);
    bus.$off('home-infinite', this.onInfinite);
    bus.$emit('home-infinite-switch', true);
  },
  mounted() {
    if (this.appDataDidLoad) {
      // @ts-ignore
      this.loadGifts({ flush: true }).then(() => {
        this.initInfinite();
      });
    }

    bus.$on('appDataDidLoad', this.loadGifts);
  },
  computed: {
    branches(): IBranch[] {
      return this.appData.branches;
    },
    nonBranchGiftsInfinite(): Array<Gift | Coupon> {
      return this.itemsPerPage
        ? this.nonBranchGifts.slice(0, this.page * this.itemsPerPage)
        : this.nonBranchGifts;
    },
    nonBranchGifts(): Array<Gift | Coupon> {
      return this.itemsComputed.filter((item) => !item.idBranch);
    },
    totalPages(): number {
      return Math.ceil(this.nonBranchGifts.length / this.itemsPerPage);
    },
    ...appStoreMapper.mapState(['appData', 'domain', 'appDataLoading', 'appDataDidLoad']),
    ...giftsStoreMapper.mapGetters(['settings', 'itemsComputed']),
    ...giftsStoreMapper.mapState(['gifts', 'coupons', 'items']),
  },
  methods: {
    onAppDataDidLoad() {
      if (this.createdDomain === this.domain) {
        this.loadGifts({ flush: true });
      }
    },
    loadGifts({ flush }: { flush?: boolean } = {}) {
      if (!this.appData || this.appDataLoading) {
        return Promise.resolve(false);
      }

      return this.getGifts({ flush }).then(() => {
        this.page = 1;
        this.isLoaded = true;

        bus.$emit('home:lazy:update');
        bus.$emit('home-infinite-switch', true);
      });
    },
    initInfinite() {
      this.itemsPerPage = this.settings.itemsPerPage || 0;

      bus.$off('home-infinite', this.onInfinite);

      if (this.itemsPerPage && this.settings.type !== GiftsListType.swiper) {
        bus.$on('home-infinite', this.onInfinite);
        bus.$emit('home-infinite-switch', true);
      } else {
        bus.$emit('home-infinite-switch', false);
      }
    },
    onInfinite(): void {
      if (this.page < this.totalPages) {
        this.page++;
      } else {
        bus.$emit('home-infinite-switch', false);
      }
    },
    getItemsByBranchId(id: number | string): Array<Gift | Coupon> {
      return this.itemsComputed.filter(
        (item) => item.idBranch.toString() === id.toString(),
      );
    },
    ...giftsStoreMapper.mapActions(['getGifts']),
  },
});
