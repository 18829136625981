

















import { defineComponent } from 'vue';
import { f7 } from 'framework7-vue';

import { ISendFeedbackResponse } from '../types';

import { feedbackStoreMapper } from '../store';
import bus from '@/bus';
import { openUrl } from '@/misc';
import { getBaseUrlOnlyHost } from '@/api';

export default defineComponent({
  data: () => ({
    feedback: null as Nullable<ISendFeedbackResponse>,
  }),
  created() {
    bus.$on('feedback:external:show', (feedback: ISendFeedbackResponse) => {
      this.feedback = feedback;
      f7.sheet.open('.feedback-sheet-external');
    });
  },
  computed: {
    ...feedbackStoreMapper.mapGetters(['moduleIsActive']),
  },
  methods: {
    onClick(e: Event) {
      const url =
        getBaseUrlOnlyHost() +
        `/wallet/frontend/#/feedback?token=${this.feedback!.authToken}&idFeedback=${
          this.feedback!.idFeedback
        }&idBranch=${this.feedback!.idBranch}`;
      openUrl(url, '_system', e);
      f7.sheet.close('.feedback-sheet-external');
    },
  },
});
