











import Vue from 'vue';
import { defineComponent, PropType } from 'vue';

import { required } from 'src/validators/validators';

import ListInput from './list-input.vue';

export default defineComponent({
  name: 'ListInputText',
  components: { ListInput },
  props: {
    name: String as PropType<string>,
    type: String,
    required: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    showError: Boolean as PropType<boolean>,
    readonly: Boolean as PropType<boolean>,
    value: {
      type: null,
    },
    label: {
      type: String as PropType<string>,
      default: () => '',
    },
    placeholder: {
      type: String as PropType<string>,
      default: () => '',
    },
    forceErrorText: String as PropType<string>,
    info: String as PropType<string>,
    clearButton: Boolean as PropType<boolean>,
    pattern: String as PropType<string>,
    inputmode: String as PropType<string>,
  },
  data: () => ({
    validationParams: {
      $autoDirty: true,
    } as any,
  }),
  watch: {
    required: {
      handler(value: boolean) {
        if (value) {
          this.validationParams.required = required;
        } else {
          Vue.delete(this.validationParams, 'required');
        }
      },
      immediate: true,
    },
  },
});
