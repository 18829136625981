













import Vue from 'vue';
import { f7 } from 'framework7-vue';

import {appStoreMapper} from "@/store/appstore";
import { bookingStoreMapper } from '../store';

import BookingHomeButtonLogoSlot from './booking-home-button-logo-slot.vue';

export default Vue.extend({
  name: 'BookingHomeSlotButton',
  components: { BookingHomeButtonLogoSlot },
  computed: {
    show(): boolean {
      return !!this.settings.homeShowButton;
    },
    ...appStoreMapper.mapGetters(['moduleIsActive']),
    ...bookingStoreMapper.mapGetters(['settings']),
  },
  methods: {
    openBooking() {
      f7.views.current.router.navigate('/booking/');
    },
  },
});
