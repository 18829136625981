
import { defineComponent, onMounted, ref, useCssModule, PropType } from 'vue';
import { mask } from 'vue-the-mask';
import { theme } from 'framework7-vue';

import { MultipleSelectType } from '@/types/enum';

import inputValidation from 'src/validators/input-validation';

export default defineComponent({
  directives: {
    mask,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: [String, Number] as PropType<Nullable<string | number>>,
    validation: {
      type: null,
    },
    type: String,
    showError: Boolean,
    label: String,
    placeholder: String,
    'error-message': String,
    info: String,
    mask: String,
    resizable: Boolean,
    required: Boolean,
    multiple: { type: Array as PropType<MultipleSelectType[]>, default: () => [] },
    isMultiple: { type: Boolean, default: false },
    idKey: { type: null },
    labelKey: { type: null },
    radio: Boolean,
    readonly: Boolean,
    step: String,
    forceErrorText: String,
    options: { type: Array, default: () => [] },
    clearButton: Boolean,
    pattern: String,
    inputmode: String,
  },
  setup(props, context) {
    const input = ref(null);
    const styles = useCssModule();

    const validation = inputValidation(props, context);

    onMounted(() => {
      if (props.type === 'date' && input.value && theme.ios) {
        const placeholder = document.createElement('div');
        placeholder.classList.add(styles.placeholder);
        placeholder.innerHTML = props.placeholder || '';
        const inputWrap = (input.value as unknown as Vue).$el.querySelector(
          '.item-input-wrap',
        );
        if (inputWrap) {
          inputWrap.prepend(placeholder);
          const input = inputWrap.querySelector('input');
          if (input) {
            input.addEventListener('change', () => {
              if (props.value) {
                placeholder.classList.add(styles.hidden);
              } else {
                placeholder.classList.remove(styles.hidden);
              }
            });
          }
        }
      }
    });

    return { input, ...validation };
  },
  render(this: any, h) {
    if (this.$props.mask) {
      return h(
        'f7-list-input',
        {
          props: {
            label: this.$props.label,
            info: this.$props.info,
            input: false,
            'error-message': this.errorText,
            'error-message-force':
              this.$props.showError && this.v$[this.fieldName].$invalid,
            readonly: this.readonly,
            'clear-button': this.clearButton,
          },
        },
        [
          h('f7-input', {
            props: {
              type: this.$props.type,
              placeholder: this.$props.placeholder,
              value: this.field,
              readonly: this.readonly,
              'clear-button': this.clearButton,
            },
            on: {
              input: (e: any) => {
                // Хак для Safari, без этого глючит маска
                // Вероятно дело в том, что несколько ивентов одновременно выстреливает
                if (this.$theme.ios) {
                  setTimeout(() => {
                    this.setValue(e);
                  }, 10);
                } else {
                  this.setValue(e);
                }
              },
              blur: () => {
                this.emitState();
              },
            },
            directives: [
              {
                name: 'mask',
                value: this.$props.mask,
              },
            ],
            slot: 'input',
          }),
        ],
      );
    } else {
      if (this.$props.type === 'select') {
        return h(
          'f7-list-input',
          {
            props: {
              type: this.$props.type,
              label: this.$props.label,
              value: this.field,
              'error-message': this.$props.forceErrorText || this.errorText,
              'error-message-force':
                !!this.$props.forceErrorText ||
                (this.$props.showError && this.v$[this.fieldName].$invalid),
              readonly: this.readonly,
              'clear-button': this.clearButton,
            },
            on: {
              input: (e: any) => {
                this.setValue(e);
              },
              blur: () => {
                this.$emit('blur');
                this.emitState();
              },
            },
          },
          this.$props.options.reduce(
            (acc: any, cur: any) => {
              acc.push(h('option', { attrs: { value: cur.value } }, cur.name));
              return acc;
            },
            [h('option', { attrs: { value: '' } }, this.$props.placeholder)],
          ),
        );
      } else {
        return h('f7-list-input', {
          ref: 'input',
          props: {
            type: this.$props.type,
            label: this.$props.label,
            placeholder: this.$props.placeholder,
            info: this.$props.info,
            value: this.field,
            resizable: this.resizable,
            'error-message': this.$props.forceErrorText || this.errorText,
            'error-message-force':
              !!this.$props.forceErrorText ||
              (this.$props.showError && this.v$[this.fieldName].$invalid),
            readonly: this.readonly,
            step: this.step,
            'clear-button': this.clearButton,
            pattern: this.$props.pattern,
            inputmode: this.$props.inputmode,
          },
          class: {
            [this.$style.multiple]: this.$props.isMultiple,
            multiple: this.$props.isMultiple,
            [this.$style.clearButton]: this.$props.clearButton,
          },
          on: {
            input: (e: any) => {
              this.setValue(e);
              this.$emit('custom-input', e);
            },
            blur: () => {
              this.$emit('blur');
              this.emitState();
            },
            focus: () => {
              this.$emit('focus');
            },
            inputClear: () => {
              this.$emit('inputClear');
            },
          },
          nativeOn: {
            keypress: (e: KeyboardEvent) => {
              this.$emit('keypress', e);
            },
            keydown: (e: KeyboardEvent) => {
              this.$emit('keydown', e);
            },
            keyup: (e: KeyboardEvent) => {
              this.$emit('keyup', e);
            },
            click: () => {
              if (this.$props.isMultiple) {
                this.$store.commit('setMultipleSelect', {
                  data: JSON.parse(JSON.stringify(this.$props.multiple)),
                  title: this.$props.placeholder,
                  idKey: this.$props.idKey,
                  labelKey: this.$props.labelKey,
                  type: this.$props.radio
                    ? MultipleSelectType.radio
                    : MultipleSelectType.checkbox,
                });

                const popup = this.$f7?.popup.open('.multiple-popup');

                popup.once('close', () => {
                  const textarea = this.$el.querySelector('textarea');
                  const values = this.$store.state.multipleSelect.data as any[];
                  const labelKey = this.$store.state.multipleSelect.labelKey;

                  const innerHTML = values
                    .reduce((acc, item) => {
                      if (item.checked) {
                        acc.push(item[labelKey]);
                      }
                      return acc;
                    }, [])
                    .join(', ')
                    .trim();

                  if (textarea) {
                    textarea.innerHTML = innerHTML;
                    this.$emit('input', innerHTML);
                  }

                  this.$emit('values', JSON.parse(JSON.stringify(values)));
                });

                popup.once('closed', () => {
                  this.$store.commit('setMultipleSelect', null);
                });
              }
            },
          },
        });
      }
    }
  },
});
