


































































import { defineComponent } from 'vue';
import { ContentLoader } from 'vue-content-loader';
import { f7 } from 'framework7-vue';

import { CatalogListType, ICategory, ReceiveType } from '../types';

import { appStoreMapper } from '@/store/appstore';
import { catalogStoreMapper } from '../store';
import bus from '@/bus';
import getImageThumbnailPath from '@/utils/getImageThumbnailPath';
import config from '@/app.config.js';

import CatalogItem from '@/components/CatalogItem.vue';
import DeliverySelect from './DeliverySelect.vue';

export default defineComponent({
  name: 'CatalogHomeSlot',
  components: { CatalogItem, DeliverySelect, ContentLoader },
  data: () => ({
    CatalogListType,
    config,
    createdDomain: '',
    swiperParams: {
      slidesPerView: 'auto',
      spaceBetween: 15,
    },
    loading: false,
    isLoaded: false,
  }),
  created() {
    this.createdDomain = this.domain;
  },
  destroyed() {
    bus.$off('appDataDidLoad', this.onAppDataDidLoad);
  },
  mounted() {
    if (this.appDataDidLoad) {
      this.updateCategories({ flush: true });
    }

    bus.$on('appDataDidLoad', this.onAppDataDidLoad);
  },
  computed: {
    ...appStoreMapper.mapGetters(['fullImagePath', 'deviceWidth']),
    ...appStoreMapper.mapState(['appData', 'appDataLoading', 'domain', 'appDataDidLoad']),
    ...catalogStoreMapper.mapGetters(['settings', 'receiveAvailable', 'receiveBranchId']),
    ...catalogStoreMapper.mapState([
      'basket',
      'pickupPoint',
      'receive',
      'deliveryAddress',
      'categories',
    ]),
  },
  methods: {
    onAppDataDidLoad() {
      if (this.createdDomain === this.domain) {
        this.updateCategories({ flush: true });
      }
    },
    updateCategories({ flush }: { flush?: boolean } = {}) {
      if (!this.appData || this.appDataLoading) {
        return false;
      }

      this.loading = true;

      this.getCategories({ flush })
        .then(() => {
          this.isLoaded = true;
          bus.$emit('home:lazy:update');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToCategory(category: ICategory) {
      if (
        (this.receive === ReceiveType.pickup && !this.pickupPoint) ||
        (this.receive === ReceiveType.delivery && !this.deliveryAddress)
      ) {
        bus.$emit('delivery:block');
      } else {
        f7.views.current.router.navigate(`/catalog/category/${category.id}`);
      }
    },
    getCategoryImage(photoId: Nullable<number>): string {
      if (!photoId) return '';

      return this.fullImagePath(
        getImageThumbnailPath(photoId, { deviceWidth: this.deviceWidth }),
      );
    },
    onSearchClick() {
      f7.views.current.router.navigate({ path: '/catalog/search' });
    },
    ...catalogStoreMapper.mapActions(['getCategories']),
  },
  watch: {
    receiveBranchId(value: Nullable<number>, prev: Nullable<number>) {
      if (value != prev) {
        this.updateCategories({ flush: true });
      }
    },
  },
});
