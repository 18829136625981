










import Vue, { PropType } from 'vue';

import { mapsStoreMapper } from '@/modules/maps/dist/store';
import bus from '@/bus';

export default Vue.extend({
  name: 'MapBlock',
  props: {
    points: {
      type: Array as PropType<IMapPoint[]>,
      default: () => [],
    },
    center: {
      type: Object as PropType<IMapPosition>,
      required: true,
      validator: (value: IMapPosition) =>
        Number.isFinite(value.lat) && Number.isFinite(value.lng),
    },
    zoom: {
      type: Number as PropType<number>,
      default: 12,
    },
    selected: {
      type: Object as PropType<IMapPoint>,
    },
    location: {
      type: Object as PropType<IMapPosition>,
      required: true,
    },
    centerMarker: Boolean as PropType<boolean>,
  },
  data: () => ({
    map: null as Nullable<any>,
    markers: [] as Array<[any, IMapPoint]>,
    locationMarker: null as any,
    centerCoords: null as Nullable<IMapPosition>,
    markerRaised: false,
    userInteracted: false,
  }),
  computed: {
    gMaps(): any {
      return window.google.maps;
    },
    ...mapsStoreMapper.mapGetters(['settings']),
  },
  mounted() {
    setTimeout(() => {
      this.startMap();
    }, 1000);

    bus.$on(
      'catalog:delivery:map:set-center',
      ({ lat, lng, zoom }: { lat: string; lng: string; zoom: number }) => {
        this.userInteracted = false;
        this.map.setCenter(new this.gMaps.LatLng(lat, lng));
        if (zoom) {
          this.map.setZoom(zoom);
        }
        this.userInteracted = true;
      },
    );
  },
  methods: {
    onMapInit() {
      this.points.forEach((point: IMapPoint) => {
        const marker = new this.gMaps.Marker({
          position: point.position,
          map: this.map,
          icon: {
            url: require('../assets/images/marker.png'),
            scaledSize: new window.google.maps.Size(40, 60),
          },
        });

        if (point.shape && this.settings.showDeliveryZones) {
          if (point.shape.type === 'circle') {
            new window.google.maps.Circle({
              map: this.map,
              radius: point.shape.radius,
              center: point.position,
              fillColor: point.shape.fillColor || '#009be1',
              fillOpacity: point.shape.fillOpacity ?? 0.35,
              strokeColor: point.shape.strokeColor || '#009be1',
              strokeOpacity: point.shape.strokeOpacity ?? 0.8,
              strokeWeight: point.shape.strokeWeight ?? 2,
            });
          } else if (point.shape.type === 'polygon') {
            new window.google.maps.Polygon({
              map: this.map,
              paths: point.shape.paths,
              fillColor: point.shape.fillColor || '#009be1',
              fillOpacity: point.shape.fillOpacity ?? 0.35,
              strokeColor: point.shape.strokeColor || '#009be1',
              strokeOpacity: point.shape.strokeOpacity ?? 0.8,
              strokeWeight: point.shape.strokeWeight ?? 2,
            });
          }
        }

        marker.addListener('click', () => {
          this.$emit('marker:click', point);
        });

        this.markers.push([marker, point]);
      });

      this.showCurrentLocation();
    },
    startMap() {
      let config = {
        mapType: this.gMaps.MapTypeId.ROADMAP,
        center: this.center,
        preferences: {
          zoom: {
            minZoom: 8,
            maxZoom: 18,
          },
        },
        zoom: this.zoom,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        clickableIcons: false,
      };

      this.map = new this.gMaps.Map(this.$refs.container as HTMLElement, config);

      new this.gMaps.event.trigger(this.map, 'resize');

      this.$emit('map:init', this.map);

      this.onMapInit();

      this.map.addListener('dragstart', () => {
        this.markerRaised = true;
        this.userInteracted = true;
      });

      this.map.addListener('dragend', () => {
        this.markerRaised = false;
        this.boundsChanged();
      });
    },
    boundsChanged() {
      if (this.centerMarker && this.userInteracted) {
        setTimeout(() => {
          this.centerCoords = {
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng(),
          };

          this.$emit('coords:center', this.centerCoords);
        }, 100);
      }
    },
    showCurrentLocation() {
      if (!this.location.lat && !this.location.lng) {
        return;
      }

      this.locationMarker = new this.gMaps.Marker({
        position: this.location,
        map: this.map,
        icon: {
          url: require('../assets/images/location.png'),
          scaledSize: new window.google.maps.Size(87, 87),
          anchor: new window.google.maps.Point(43, 43),
        },
      });
    },
  },
  watch: {
    markers() {
      this.$emit('marker:list', this.markers);
    },
    center() {
      if (this.map && this.center) {
        this.map.setCenter(new this.gMaps.LatLng(this.center.lat, this.center.lng));
        this.map.setZoom(this.selected ? 16 : this.zoom);
      }
    },
    points() {
      this.markers.forEach((entry) => {
        entry[0].setMap(null);
      });

      this.markers = [];

      this.onMapInit();
    },
    location: {
      handler() {
        if (this.locationMarker) {
          this.locationMarker.setMap(null);
        }

        this.showCurrentLocation();
      },
      immediate: true,
      deep: true,
    },
  },
});
