
import { defineComponent } from 'vue';

import { slotSeeker } from '@/utils';

export default defineComponent({
  functional: true,
  render() {
    return slotSeeker('booking-home-button-logo-slot');
  },
});
