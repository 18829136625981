






















































import Vue from 'vue';

import { ICatalogHistoryItem, OrderStatus } from '../types';

import { LOCALSTORAGE_ORDER_STATUS } from '../const';

import { catalogStoreMapper } from '../store';
import { loadHistory } from '../api';
import Storage from '@/storage';
import { appStoreMapper } from '@/store/appstore';
import bus from '@/bus';

export default Vue.extend({
  name: 'HistoryBar',
  data: () => ({
    OrderStatus,
    item: null as Nullable<ICatalogHistoryItem>,
    visible: false,
    updateInterval: null as any,
    prevDomain: '',
  }),
  created() {
    this.prevDomain = this.domain;
    this.setOrderStatus(Storage.getSync(LOCALSTORAGE_ORDER_STATUS) || null);
  },
  mounted() {
    if (this.orderStatus && !this.orderFinished) {
      this.setShowCatalogHistoryBar(true);
    }

    bus.$on(
      'tabbar:show',
      () => (this.visible = this.currentTab === 'home' && !this.orderFinished),
    );
    bus.$on('tabbar:hide', () => (this.visible = false));
  },
  computed: {
    orderComputed(): Nullable<ICatalogHistoryItem> {
      return this.item || this.orderStatus;
    },
    orderFinished(): boolean {
      return (
        this.orderComputed?.orderStatus === OrderStatus.done ||
        this.orderComputed?.orderStatus === OrderStatus.cancel
      );
    },
    orderStatusLabel(): string {
      return this.item
        ? this.$t('modules.catalog.history.status.' + this.item.orderStatus).toString()
        : '';
    },
    visibleComputed(): boolean {
      return (
        !(!!this.settings.showBasket && !!this.totalProducts) &&
        this.visible &&
        !!this.item
      );
    },
    ...appStoreMapper.mapState(['domain', 'authToken', 'currentTab']),
    ...catalogStoreMapper.mapGetters(['settings', 'totalProducts']),
    ...catalogStoreMapper.mapState(['orderStatus', 'showCatalogHistoryBar']),
  },
  methods: {
    onHideBarClick() {
      this.visible = false;

      this.setShowCatalogHistoryBar(false);
    },
    onItemClick() {
      this.setSelectedHistoryItem(this.item!);
      this.$f7.views.current.router.navigate('/catalog/history/body');
    },
    updateOrderStatus() {
      loadHistory({ limit: 1 }).then((response) => {
        this.item = response.items[0];

        if (!this.item) {
          this.setShowCatalogHistoryBar(false);
        }

        if (
          this.item?.orderStatus === OrderStatus.new ||
          this.item?.orderStatus === OrderStatus.process ||
          this.item?.orderStatus === OrderStatus.working
        ) {
          this.setOrderStatus(this.item);
          this.setShowCatalogHistoryBar(true);
          this.visible = true;

          if (!this.updateInterval) {
            this.updateInterval = setInterval(() => {
              this.updateOrderStatus();
            }, 60 * 1000);
          }
        } else {
          if (this.updateInterval) {
            clearInterval(this.updateInterval);
            this.updateInterval = null;
          }

          if (this.prevDomain && this.prevDomain !== this.domain) {
            this.setShowCatalogHistoryBar(false);
            this.visible = false;
          }

          this.setOrderStatus(null);
        }

        this.prevDomain = this.domain;
      });
    },
    ...catalogStoreMapper.mapMutations([
      'setOrderStatus',
      'setShowCatalogHistoryBar',
      'setSelectedHistoryItem',
    ]),
  },
  watch: {
    domain() {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
      }

      this.item = null;
      this.setOrderStatus(null);

      this.visible = false;

      this.setShowCatalogHistoryBar(false);

      setTimeout(() => {
        this.updateOrderStatus();
      }, 500);
    },
    showCatalogHistoryBar(value: boolean) {
      if (value) {
        this.updateOrderStatus();
      } else {
        this.visible = false;
      }
    },
    visibleComputed(value: boolean) {
      if (!value) {
        this.setShowCatalogHistoryBar(false);
      }
    },
    authToken() {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
      }

      this.item = null;
      this.setOrderStatus(null);

      this.visible = false;

      this.setShowCatalogHistoryBar(false);
    },
    currentTab() {
      this.visible = this.currentTab === 'home' && !this.orderFinished;
    },
  },
});
