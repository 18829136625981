




























































































import Vue from 'vue';
import { defineComponent } from 'vue';
import { f7 } from 'framework7-vue';

import { IProduct, ReceiveType } from '../types';

import config from 'src/app.config.js';
import { formatNumber } from 'src/misc';
import { appStoreMapper } from 'src/store/appstore';
import { catalogStoreMapper } from '../store';
import bus from '@/bus';
import getLocalizedPrice from '@/utils/getLocalizedPrice';

import AnimatedNumber from '@/components/AnimatedNumber.vue';
import BonusIcon from 'src/components/bonus-icon.vue';
import CatalogBarWrapper from './CatalogBarWrapper.vue';

export default defineComponent({
  name: 'Form',
  components: {
    AnimatedNumber,
    BonusIcon,
    CatalogBarWrapper,
  },
  data: () => ({
    products: [] as Array<IProduct & { selected?: boolean }>,
  }),
  mounted() {
    bus.$on('catalog:bonus-confirm', this.onContinueClicked);

    const products: Array<IProduct & { selected?: boolean }> = [];

    this.productsInBasketWithPayBonus
      .filter((entry) => !entry.product.deleted && (entry.product.active ?? true))
      .forEach((entry) => {
        for (let i = 0; i < entry.amount; i++) {
          products.push(
            JSON.parse(JSON.stringify({ ...entry.product, selected: false })),
          );
        }
      });

    this.products = products;
  },
  computed: {
    discount(): number {
      let discount = 0;

      if (this.receive === ReceiveType.pickup && this.settings.pickup?.discount) {
        discount = this.settings.pickup.discount;
      }

      return discount;
    },
    resultSum(): number {
      const sum = this.totalProductsPriceComputed;

      if (this.decimalOptions.decimal != null) {
        return sum;
      } else {
        return Math.round(sum);
      }
    },
    totalBonus(): number {
      let sum = 0;

      this.products.forEach((product) => {
        if (product.selected) {
          sum += Math.floor(
            (product.price *
              ((100 - this.discount) / 100) *
              Math.min(product.maxPayBonus, this.settings.maxPayBonusK)) /
              100,
          );
        }
      });

      return sum;
    },
    totalProductsPriceComputed(): number {
      switch (this.receive) {
        case ReceiveType.delivery:
          return this.totalPriceDelivery - this.totalBonus;
        case ReceiveType.pickup:
          return this.totalPricePickup - this.totalBonus;
        default:
          return this.totalProductsPrice - this.totalBonus;
      }
    },
    totalProductsPriceFormatted(): string {
      return this.formatNumberDecimal(this.totalProductsPriceComputed);
    },
    ...appStoreMapper.mapGetters([
      'clientName',
      'miniappHideElements',
      'user',
      'decimalOptions',
      'currency',
    ]),
    ...catalogStoreMapper.mapGetters([
      'settings',
      'totalProductsPrice',
      'totalPriceDelivery',
      'totalPricePickup',
      'productsInBasketWithPayBonus',
    ]),
    ...catalogStoreMapper.mapState(['receive']),
  },
  methods: {
    formatNumber,
    getAmountAvailableForPayByBonus(product: IProduct): number {
      return Math.floor(
        product.price *
          ((100 - this.discount) / 100) *
          (Math.min(product.maxPayBonus, this.settings.maxPayBonusK) / 100),
      );
    },
    enoughBonuses(product: IProduct): boolean {
      return (
        Math.floor(
          product.price *
            ((100 - this.discount) / 100) *
            (Math.min(product.maxPayBonus, this.settings.maxPayBonusK) / 100),
        ) <=
        (this.user?.bonus || 0) - this.totalBonus
      );
    },
    getImage(item: IProduct): string | null {
      return item.images.length
        ? `${config.api.protocol}://${this.clientName}.${config.api.host}/rest/image/${
            item.images[0]
          }/0/${Math.floor(window.innerWidth / 4)}/scale.png`
        : null;
    },
    isChecked(product: IProduct & { selected?: boolean }): boolean {
      return !!product.selected;
    },
    onContinueClicked() {
      f7.views.current.router.navigate('/catalog/form');
    },
    onProductClicked(product: IProduct & { selected?: boolean }) {
      Vue.set(product, 'selected', !product.selected);

      const products = this.products.filter((product) => product.selected);
      this.setPaidByBonus(JSON.parse(JSON.stringify(products)));
    },
    formatPriceValue(value: number) {
      if (value == null) {
        return getLocalizedPrice(value, this.currency);
      }

      if (this.decimalOptions.decimal == null) {
        return this.localizePrice(Math.round(value));
      }

      return this.localizePrice(value);
    },
    formatBonusValue(value: number) {
      return this.formatNumberDecimal(value);
    },
    onPageBeforeIn() {
      this.setInBonus(true);
    },
    onPageBeforeOut() {
      this.setInBonus(false);
    },
    localizePrice(value: number, onlyNumber = false): string {
      return getLocalizedPrice(
        value,
        this.currency,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
        {
          useMils: true,
          onlyNumber,
        },
      );
    },
    formatNumberDecimal(value: number): string {
      if (!this.decimalOptions.decimal) {
        return formatNumber(Math.round(value));
      }

      if (!value) {
        return '0';
      }

      return formatNumber(
        value,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
    ...catalogStoreMapper.mapMutations(['setPaidByBonus', 'setInBonus']),
  },
  watch: {
    totalBonus: {
      handler(value: number) {
        bus.$emit('catalog:total-bonus', value);
      },
      immediate: true,
    },
  },
});
