











import Vue from 'vue';

import ListInputText from '@/components/fields/list-input-text.vue';

export default Vue.extend({
  name: 'FieldInput',
  inheritAttrs: false,
  components: { ListInputText },
});
